import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import { useMemo, useState } from "react";
import { OrganisationBindingRole, OrganisationId, UserWithRole } from "src/api/models/UserProfile";
import { TableReport } from "@impulso/common/components/report/TableReport";
import { Loader, Menu, Select, UnstyledButton } from "@mantine/core";
import TrashCan from "@impulso/common/Icons/TrashCan";
import { useUpdateUserMutation as useUpdateUserRoleMutation, useRemoveUserMutation } from "src/api/UserApi";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";

type UserHeaderProps = {
    isAdmin: boolean,
    organisationId: OrganisationId,
    users: UserWithRole[]
}

export default function UsersReport(props: UserHeaderProps) {

    const columnData = useMemo(() => GetColumnData(props.isAdmin), []);

    function getIdAccessor(user: UserTable): string {
        return user.id;
    }

    return ( 
        <div>
            <TableReport rows={props.users} columns={columnData} pageSize={25} defaultSort={{accessor: "name", direction: "asc"}} idAccessor={getIdAccessor} isLoading={false} loadingText="Loading" />
        </div>
    );
}


function GetColumnData(isAdmin: boolean): TableReportColumn<UserTable>[] {

    const name:         TableReportColumn<UserTable> = { accessor: "name",              sortable: true,     title: "Name",          visibility: "alwaysVisible" };
    const organisation: TableReportColumn<UserTable> = { accessor: "organisationName",  sortable: true,     title: "Organisation",  visibility: "visible" };
    const role:         TableReportColumn<UserTable> = { accessor: "role",              sortable: true,     title: "Role",          visibility: "visible",      render: (user) => <Role organisationId={user.organisationId} user={user} isAdmin={isAdmin} />, definedWidth: " min-w-[135px]"};
    const email:        TableReportColumn<UserTable> = { accessor: "email",             sortable: true,     title: "E-mail",        visibility: "visible" };
    const invited:      TableReportColumn<UserTable> = { accessor: "isInvited",         sortable: true,     title: "Invited",       visibility: "visible" ,      render: (user) => <Invited isInvited = {user.isInvited} /> };     
    const phoneNumber:  TableReportColumn<UserTable> = { accessor: "phoneNumber",       sortable: true,     title: "Phone Number",  visibility: "visible" };
    const trashCan:     TableReportColumn<UserTable> = { accessor: "trashCan",          sortable: false,    title: "Remove",        visibility: "visible",      render: (user) => <RemoveUser organisationId={user.organisationId} organisationName={user.organisationName} userId={user.id} userName={user.name} isInvited = {user.isInvited}/>, textAlignment: "right"};

    const returnList = [
        name, organisation, role, email,invited, phoneNumber, 
    ];

    if(isAdmin) {
        returnList.push(trashCan);
    }

    return returnList;
};

interface UserTable extends UserWithRole {
    trashCan?: undefined
}

interface RemoveUserProps {
    organisationId: OrganisationId,
    organisationName: string,
    userId: string,
    userName: string,
    isInvited : boolean | undefined,
}

function RemoveUser(props: RemoveUserProps) {
    const [RemoveUser, response] = useRemoveUserMutation();
    const [opened, setOpened] = useState(false);
    
    function clickButton()
    {
        RemoveUser({organisationId: props.organisationId, userId: props.userId}); 
        setOpened(false);
    }

    if(response.isLoading)
    {
        return (
            <div className="flex w-full h-full justify-end">
                <Loader color="brand" size={16}/>
            </div>
        );
    }

    return (
        <div className="flex w-full h-full justify-end">
            {props.isInvited != true &&
            <Menu position="left-start" opened={opened} onChange={setOpened}>
                <Menu.Target>
                    <UnstyledButton className="align-middle hover:text-brand-900"><TrashCan/></UnstyledButton>
                </Menu.Target>

                <Menu.Dropdown className="p-4 text-sm min-w-[100px] max-w-[300px]">
                    <div className="pb-4">Delete {props.userName} from {props.organisationName}?</div>
                    <PrimaryButton label={"Delete"} onClick={() => clickButton()}></PrimaryButton>
                </Menu.Dropdown>
            </Menu>}
        </div>
    );
}

interface EditRoleProps {
    organisationId: OrganisationId,
    user: UserWithRole,
    isAdmin: boolean
}

function Invited (props : {isInvited : boolean | undefined}) {
    return props.isInvited ? <span>Invited</span> : <></>
}

function Role(props: EditRoleProps) {
    const [role, setRole] = useState<OrganisationBindingRole | undefined>(undefined);
    const [UpdateUser, request ] = useUpdateUserRoleMutation();

    if(!props.isAdmin) {
        return <div>{props.user.role}</div>;
    }

    const isLoading = request.isLoading;

    function RoleEdited( role: string | null) {

        if (role != null)
        {
            UpdateUser({organisationId: props.organisationId, userId: props.user.id, newRole: role as OrganisationBindingRole});
            setRole(role as OrganisationBindingRole);
        }
    }

    return (
        <Select 
            label="" 
            styles={dropdownStyling} 
            value={role ?? props.user.role} 
            onChange={(value) => props.user.isInvited != true && RoleEdited(value) }  
            data={props.user.isInvited == true ? [props.user.role!] : ['Member', 'Administrator']} 
            disabled={isLoading}
        />
    );

}
