import { Tabs, TabsValue } from "@mantine/core";
import { useState } from "react";
import { useGetNotificationsQuery } from "../../api/NotificationApi";
import { useOrganisationId } from "../../common/security/UseGlobalSecurity";
import minutesToMilliseconds from "date-fns/minutesToMilliseconds";
import {networkToClient, notificationFilterFunc, NotificationList, TabValue} from "./Notification";

interface TabItemProps{
  value: TabValue;
  currentTab: TabValue;
  label: string;
}

function TabItem({value, currentTab, label}: TabItemProps) {
  return <Tabs.Tab value={value}>
    <span className={currentTab === value ? "text-brand-600" : "text-gray-400"}>{label}</span>
  </Tabs.Tab>;
}

export interface NotificationDrawerProps {
  onClose(): void;
}

export function NotificationDrawer(props: NotificationDrawerProps) {
  const [currentTab, setCurrentTab] = useState("inbox" as TabValue);
  const organisationId = useOrganisationId();

  const {data: requestNotifications} = useGetNotificationsQuery(
    {organisationId: organisationId!}, {
      skip: !organisationId,
      pollingInterval: minutesToMilliseconds(5)
    });

  function onTabChanged(value: TabsValue) {
    if (value != null) {
      setCurrentTab(value as TabValue);
    }
  }

  const notifications = requestNotifications
    ?.map(networkToClient)
    .filter(notificationFilterFunc(currentTab))
    .sort((n, n2) => n2.created.getTime() - n.created.getTime()) ?? [];

  return <div className="mt-4 flex flex-col overflow-y-hidden">
    <Tabs value={currentTab} onTabChange={onTabChanged} className="flex flex-col overflow-y-hidden">
      <Tabs.List grow>
        <TabItem value="inbox" currentTab={currentTab} label="Inbox"/>
        <TabItem value="complete" currentTab={currentTab} label="Complete"/>
      </Tabs.List>
        <NotificationList notifications={notifications} onClose={props.onClose} />
    </Tabs>
  </div>;
}