import {AnyAction, combineReducers, configureStore, ThunkAction as RtkThunkAction} from '@reduxjs/toolkit'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import userSlice from "./slices/UserSlice"
import organisationSlice from "./slices/OrganisationSlice";
import fortnoxSlice from "./slices/FortnoxSlice"
import csrfSlice from "./slices/CsrfSlice";
import initSlice from "./slices/init"
import {portalApi, publicPortalApi} from "./api/base/PortalApi";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import shopifySlice from "./slices/ShopifySlice";

const persistConfig = {
    key: 'impulso',
    storage,
    whitelist: ["notice", "csrf", "organisation"]
}

const rootReducer = combineReducers({
    user: userSlice,
    organisation: organisationSlice,
    fortnox: fortnoxSlice,
    shopify: shopifySlice,
    csrf: csrfSlice,
    init: initSlice,
    [portalApi.reducerPath]: portalApi.reducer,
    [publicPortalApi.reducerPath]: publicPortalApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: 
        {
            // redux-persist needs to put non-serializable data in its actions, see https://github.com/rt2zz/redux-persist/issues/988
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]

        }
    })
        .concat(portalApi.middleware)
        .concat(publicPortalApi.middleware)
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ThunkAction<ReturnType> = RtkThunkAction<ReturnType, RootState, unknown, AnyAction>
