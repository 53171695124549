export default function MenuIcon(){

    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1802_8129)">
            <path d="M0.666504 1.33325H15.3332" stroke="currentcolor" strokeWidth="1.5"/>
            <path d="M0.666504 8H15.3332" stroke="currentcolor" strokeWidth="1.5"/>
            <path d="M0.666504 14.6667H15.3332" stroke="currentcolor" strokeWidth="1.5"/>
        </g>
        <defs>
            <clipPath id="clip0_1802_8129">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    

}
