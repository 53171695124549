import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {ThunkAction} from "../store";
import differenceInMinutes from "date-fns/differenceInMinutes";
import parseISO from "date-fns/parseISO";

export type CsrfToken = {
    created: string,
    token: string,
}

/**
 * This slice holds persistent data used to protect certain workflows with Cross-Site Request Forgery tokens.
 *
 * It's persisted using `redux-persist`
 */
export type CsrfSlice = {
    fortnox?: CsrfToken
    shopify?: CsrfToken
}

const slice = createSlice({
    name: "csrf",
    initialState: { } as CsrfSlice,
    reducers: {
        setToken: (state, action: PayloadAction<{key: keyof CsrfSlice, state: CsrfToken}>) => {
            state[action.payload.key] = action.payload.state;
        },
        clearToken: (state, action: PayloadAction<keyof CsrfSlice>) => {
            delete state[action.payload];
        }
    }
});

export const {clearToken} = slice.actions;

export default slice.reducer;

export function createCsrfToken(key: keyof CsrfSlice): ThunkAction<string> {
    return (dispatch) => {
        const created = (new Date()).toISOString();
        const token = crypto.randomUUID();

        dispatch(slice.actions.setToken({key, state: {created, token}}));

        return token;
    }
}

/**
 *
 * @returns error message or null
 */
export function validateToken(state: CsrfToken | undefined, stateQuery: string | null): string | null {
    if (!state) {
        return "Callback without CSRF state";
    }

    if (differenceInMinutes(new Date(), parseISO(state.created)) > 10) {
        return "CSRF token expired";
    }

    if (!stateQuery || stateQuery !== state.token) {
        return "CSRF token is incorrect";
    }

    return null;
}
