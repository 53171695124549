import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryMeta} from "@reduxjs/toolkit/query/react";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import { RootState } from "../../store";

/**
 * @param baseUrl
 * @param authenticated - Use token from Auth0 to authorize the request
 */
export function baseQuery(baseUrl: string, authenticated: boolean = true): QueryFn {

    const base = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, {getState, endpoint}) => {
            if (authenticated) {
                const token = (getState() as RootState).user.token;

                if (!token) {
                    throw new Error(`Required authorization token not set when creating request for ${endpoint}`);
                }

                headers.set("Authorization", `Bearer ${token}`);
            }

            return headers;
        }
    });

    const queryFn: QueryFn = async (args, api, extraOptions) => {
        const res = await base(args, api, extraOptions);

        if (res.error) {
            const error = res.error as FetchBaseQueryError;
            if (error.status === 401 && authenticated) {
                document.location.href = document.location.origin;
            }
        }

        return res;
    }

    return queryFn;
}

export type QueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>;