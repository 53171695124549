import {portalApi} from "./base/PortalApi";
import { CellType } from "./models/ArticleColumns";
import { Article as ArticleModel } from "./Models";
import { OrganisationId} from "./models/UserProfile";

export const articlesApi = portalApi.injectEndpoints({
    endpoints: builder => ({
        uploadArticleRegistry: builder.mutation<number, {query: { organisationId: OrganisationId, seasonId: string, brand: string }, body: FormData}>({
            query: ({query, body}) => ({
                url: `/api/articles/UploadArticleRegistry?organisationId=${query.organisationId}&seasonId=${query.seasonId}&brand=${encodeURIComponent(query.brand)}`,
                method: 'POST',
                body
            })
        }),
        uploadSingleArticle: builder.mutation<void, {query: {organisationId: OrganisationId, seasonId: string, brand: string}, body: ArticleModel}>({
            query: ({query, body}) => ({
                url: `/api/articles/UploadSingleArticle?organisationId=${query.organisationId}&seasonId=${query.seasonId}&brand=${encodeURIComponent(query.brand)}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['article']
        }),
        getArticleColumns: builder.mutation<ArticleColumnResponse[], { organisationId: OrganisationId, body: FormData}>({
            query: ({organisationId, body}) => ({
                url: `/api/articles/GetArticleColumns?organisationId=${organisationId}`,
                method: 'POST',
                body
            }),
            transformResponse: (response: any[]) => response.map(response => ({...response, fields: response.fields && JSON.parse(response.fields)}))
        }),
        GetUploadArticleRegistryFilter: builder.query<ArticleUploadFilterResponse, {organisationId: OrganisationId}>({
            query: params => `api/articles/GetUploadArticleRegistryFilter?organisationId=${params.organisationId}`,
            providesTags: ['seasons', 'brands']
        }),
        getArticleRegistry: builder.query<Article[], { organisationId: OrganisationId}>({
            query: params => `/api/articles/GetRegisteredArticles?organisationId=${params.organisationId}`,
            transformResponse: (response: any[]) => response.map(response => ({...response, fields: response.fields && JSON.parse(response.fields)}))
        }),
        getArticleEvent: builder.query<ArticleEventResponse[], { organisationId: OrganisationId, startDate: string, endDate: string }>({
            query: params => `/api/articles/GetArticleEvents?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}`
        }),
        getSupplierSeason: builder.query<SupplierSeason[], { organisationId: OrganisationId}>({
            query: params => `/api/articles/GetSupplierSeason?organisationId=${params.organisationId}`,
            providesTags: ['seasons']
        }),
        getSupplierBrands: builder.query<string[], { organisationId: OrganisationId}>({
            query: params => `/api/articles/GetSupplierBrands?organisationId=${params.organisationId}`,
            providesTags: ['brands']
        }),
        
        createSupplierSeason: builder.mutation<CreateSeasonResponse, CreateSeasonRequest>({
            query: ({supplierOrgId, seasonName, seasonAbbreviation}) => ({
                url: `/api/articles/CreateSeason?organisationId=${supplierOrgId}`,
                method: 'POST',
                body: {
                "Name": seasonName,
                "Abbreviation": seasonAbbreviation
                }
            }),
            invalidatesTags: ['seasons']
        }),
        createSupplierBrand: builder.mutation<CreateBrandResponse, CreateBrandRequest>({
            query: ({supplierOrgId, brandName}) => ({
                url: `/api/articles/CreateBrand?organisationId=${supplierOrgId}`,
                method: 'POST',
                body: {
                "BrandName": brandName
                }
            }),
            invalidatesTags: ['brands']
        }),
        getArticle: builder.query<ArticleResponse, { organisationId: OrganisationId, ean: string }>({
            query: params => `/api/articles/GetArticle?organisationId=${params.organisationId}&ean=${params.ean}`,
            providesTags: ['article']
        })
    })
})

export const {
    useUploadArticleRegistryMutation,
    useUploadSingleArticleMutation,
    useGetArticleColumnsMutation,
    useGetArticleRegistryQuery,
    useLazyGetArticleQuery,
    useGetUploadArticleRegistryFilterQuery,
    useGetArticleEventQuery,
    useGetSupplierSeasonQuery,
    useGetSupplierBrandsQuery,
    useCreateSupplierSeasonMutation,
    useCreateSupplierBrandMutation,
} = articlesApi;

export interface SupplierSeason{
    id: string,
    name: string,
    shortName: string
}
export interface Article {
    ean: string;
    articleNumber: string;
    brand: string;
    description: string;
    seasonName?: string;
    wholesalePriceMinor: number;
    fields: Record<string, any>;
}

export interface ArticleUploadFilterResponse {
    seasons: {
        id: string,
        name: string
    }[],
    brands: string[]
}

export interface CreateSeasonRequest {
    seasonName: string,
    seasonAbbreviation: string,
    supplierOrgId: OrganisationId
}

export interface CreateSeasonResponse {
    name: string,
    id: string,
}

export interface CreateBrandResponse {
    name: string,
}

export interface CreateBrandRequest {
    brandName: string,
    supplierOrgId: OrganisationId
}

export interface ArticleColumnResponse {
    name: string;
    type: CellType;
    dataPreview: string;
}

export interface ArticleHeaderMap {
    impulsoHeader: string;
    userHeader: string;
}

export interface ArticleEventResponse {
    id: string;
    eventType: string;
    created: Date;
    creatorUserName: string;
    creatorOrgId: OrganisationId;
    creatorOrgName: string;
    seasonName: string;
    brandName: string;
    affectedArticleAmount: number;
}

export interface ArticleResponse {
    articleNumber: string,
    brandName: string,
    seasonId: string,
    description: string,
    category?: string,
    gender?: string,
    size?: string,
    color?: string,
    colorCode?: string,
    imageUrl?: string,
    origin?: string,
    customsCode?: string,
    composition?: string,
    additionalText?: string,
    articlePricing: {wholesale: number, recommendedRetail?: number, currency: string}[]
}