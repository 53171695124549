import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {useEffect} from "react";
import {setToken} from "../../slices/UserSlice";
import {useGetUserQuery} from "../../api/UserApi";
import {setupUser} from "../intercom/Intercom";
/**
 * Should only be used in root of Auth in order to trigger login to Auth0 and intercom
 */
export function useAuth(shouldLogin: boolean) {
    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading: auth0Loading,
        getAccessTokenSilently
    } = useAuth0();

    const accessToken = useSelector((s: RootState) => s.user.token);

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!auth0Loading && !isAuthenticated && shouldLogin) {
            loginWithRedirect().catch(e => console.error(e));
        } else if (!auth0Loading && isAuthenticated) {
            getAccessTokenSilently()
                .then(token => {
                    if (accessToken !== token) {
                        dispatch(setToken(token))
                    }
                })
                .catch(e => console.error(e))
        }
    }, [loginWithRedirect, auth0Loading, isAuthenticated, getAccessTokenSilently, accessToken, dispatch]);


    const {data, error: userError, isLoading: userLoading} = useGetUserQuery(undefined, {skip: !accessToken});

    useEffect(() => {
        if (isAuthenticated && data !== undefined) {
            if (data.user.intercomId !== undefined) {
                let intercomUser = {
                    name: data.user.name,
                    email: data.user.email,
                    userId: data.user.id,
                    userHash: data.user.intercomVerification
                }
                dispatch(setupUser(intercomUser))
            }
            if (data.organisations === undefined || data.organisations.length === 0) {
                console.info("No organisation found");
            }
        }
    }, [data, isAuthenticated]);

    const isLoading = auth0Loading || userLoading;

    return {loginWithRedirect, isLoading, isAuthenticated, userError}
}