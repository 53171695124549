import {portalApi} from "./base/PortalApi";
import {OrganisationId} from "./models/UserProfile";

export const stockApi = portalApi.injectEndpoints({
    endpoints: builder => ({
        getMissingArticleCount: builder.query<{ count: number }, { organisationId: OrganisationId }>({
            query: args => ({
                url: `/api/stock/GetMissingArticleCount?organisationId=${args.organisationId}`
            })
        }),
        getMissingArticles: builder.query<{ items: MissingArticlesListItem[] }, { organisationId: OrganisationId }>({
            query: args => ({
                url: `/api/stock/GetMissingArticles?organisationId=${args.organisationId}`
            })
        })
    })
});

export const {
    useGetMissingArticleCountQuery,
    useGetMissingArticlesQuery
} = stockApi;

export type StockRequestParams = {
    organisationId: OrganisationId
}

export type MissingArticlesListItem = {
    ean: string, 
    lastReceived: string, 
    retailerName: string,
    retailerId: OrganisationId, 
    inventories: string[]
}

export type ArticleRetailerBreakdown = {
    ean: string,
    retailerOrgId: string,
    retailerName: string,
    articleNumber: string,
    name: string,
    brand: string,
    stock: number,
    deliveredAmount: number
}

export type ArticleRetailerBreakdownResult = ArticleRetailerBreakdown[];
