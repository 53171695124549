import ArrowLeft from "@impulso/common/Icons/ArrowLeft";
import { Page } from "@impulso/common/components/Page";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import { Accordion, Loader, Title } from "@mantine/core";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useNavigate } from "react-router-dom";
import {VolumeFeeStep, useGetImpulsoAgreementQuery, ImpulsoAgreementResponse, VolumeFeePlan} from "src/api/AgreementApi";
import { useGetUsersQuery } from "src/api/UserApi";
import { Organisation } from "src/api/models/UserProfile";
import {useGlobalSecurity, useOrganisation} from "src/common/security/UseGlobalSecurity";
import Paths from "src/configuration/Paths";
import Download from "@impulso/common/Icons/Download";
import { useRef, useState } from "react";
import CustomSelectElement from "@impulso/common/components/buttons/CustomDropdown";
import Folder from "@impulso/common/Icons/Folder";
import { downloadAgreementAttachment } from "src/api/DownloadApi";
import DownChevronIcon from "@impulso/common/Icons/DownChevronIcon";
import { colors } from "@impulso/common/Theme";
import FormatDate from "@impulso/common/styling/FormatDate";

export default function OrgOverviewPage() {
    const organisation = useOrganisation()!;
    const {accessToken} = useGlobalSecurity();
    const { data, error, isFetching } = useGetImpulsoAgreementQuery({organisationId: organisation.id}, {skip: !organisation});
    const { data: users } = useGetUsersQuery({ organisationId: organisation.id }, { skip: !organisation });
    const navigate = useNavigate();
    const agreement: ImpulsoAgreementResponse = data ?? {} as ImpulsoAgreementResponse;
    const [open, setOpen] = useState(false);
    

    const backButton = () => {
		return <SecondaryButton 
			label="Back" 
			margin="mr-4 mt-1" 
			leftIcon={<ArrowLeft />} 
			onClick={() => navigate(Paths.preferences.index)}
		/>
	}

    function getOrganisationType(organisation: Organisation) {
        if (organisation.isStore) {
            return "Store";
        } else if (organisation.isRetailer) {
            return "Retailer";
        } else if (organisation.isSupplier) {
            return "Supplier";
        } else {
            return "Holding";
        }
    }

    if (isFetching) {
        return <Page titleKey="settings.title" hasAccess>
            <div className="w-full, h-full min-h-[404px] flex items-center justify-center">
                <Loader />
            </div>
        </Page>
    }

    const registeredAt = data ? format(parse(data?.registeredAt, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : "";
    const startDate = data ? format(parse(data?.startDate, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : "";
    const endDate = data ? format(parse(data?.endDate, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : "";
    
    const attachments = () => {
        return(<div className="border text-S min-w-[150px] min-h-[150px]">
            {agreement.fileAttachments?.map(a => 
                <div key={a.fileId} className="pl-2 pr-4 py-2 flex gap-2 underline truncate select-none hover:cursor-pointer hover:bg-gray-200 active:bg-gray-400"
                                                      onClick={() => {setOpen(false); downloadAgreementAttachment(accessToken!, a.fileName, a.fileId, organisation.id)}}>
                <span className="aspect-square w-[16px]"><Download /></span>
                <p className="truncate">{a.fileName}</p>
            </div>)}
        </div>);
    }

    return(
        <Page responsive hasAccess titleKey="orgOverview.title" leftAction={backButton()}>
            <Title weight={400} mt={16}>{organisation.name}</Title>
            <div className="border divide-x flex text-sc w-fit mt-2">
                <p className="p-1 px-2">{getOrganisationType(organisation)}</p>
                {(data && !error) && <p className="p-1 px-2">Registered: <span className="font-semibold">{registeredAt}</span></p>}
                <p className="p-1 px-2">Users: <span className="font-semibold">{users?.length ?? '...'}</span></p>
            </div>
            <div className="my-8" />
            <div className="border p-4 pt-2">
                <Title className="mb-1" size={"x-large"} weight={400}>Impulso Agreement</Title>
                {(data && !error) ? <div className="flex grid grid-cols-2 v-tablet:grid-cols-1 gap-4 mt-4">
                        <div className="grid grid-cols-2 gap-4 content-start">
                            <BodyItem label="Start Date" value={startDate}/>
                            <BodyItem label="End Date" value={endDate}/>
                            {data.hasAutoPayment && <AutoPaymentSection feePercent={data.autoPaymentFeePercent!}/>}
                        </div>

                        <div>
                            {data.hasProductTracker && <ProductTrackerSection volumeFeePlans={data.volumeFeePlans!} currency={organisation.defaultCurrency}/>}
                        </div>
                        <div className="flex items-end">
                            <CustomSelectElement xAlign="right" open={open} setOpen={setOpen} body={attachments()}>
                                <div className={`border border-black rounded-full transition-all px-4 py-2 text-S flex gap-2 ${open ? 'bg-gray-200' : ''}`}>
                                    <Folder/><p className="mt-[1px]">Attachments <span
                                    className="font-semibold">({agreement.fileAttachments.length})</span></p></div>
                            </CustomSelectElement>
                        </div>
                    </div>
                    : <div>
                        Failed to find your impulso agreement.
                    </div>}
            </div>
        </Page>
    );
}

function AutoPaymentSection({feePercent}: { feePercent: number }) {
    return (
        <div>
            <BodyItem styling="pt-4" label="Auto Payment Fee" value={feePercent.toFixed(2) + "%"}/>
        </div>
    )
}

function ProductTrackerSection(props: {volumeFeePlans : VolumeFeePlan[], currency: string, isMobileSize?: boolean}) {
  
    const [selectedItem, setSelectedItem] = useState<string | null>(null);

    return (
        <Accordion value={selectedItem} chevron={<DownChevronIcon></DownChevronIcon>} styles={{
            item: {
                backgroundColor: "white",
                borderColor: colors.gray[300],
                borderRadius: "0px",
            },
            chevron: {
                position: "absolute",
                top: 18
            }
        }} variant={(props.isMobileSize ? "contained" : "separated")}>
            <div>
                <div className="mb-2 text-sc text-gray-600 uppercase">Product Tracker Fee</div>
                {props.volumeFeePlans.map(feePlan => <ProductTrackerItem volumeFeePlan={feePlan} currency={props.currency} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>)}
            </div>
        </Accordion>
    );
}

function ProductTrackerItem(props: {volumeFeePlan: VolumeFeePlan, currency: string, selectedItem:  string | null, setSelectedItem: (selectedItem: string | null) => void}) {
    const reportElement = useRef<HTMLDivElement>(null);
    const color = props.selectedItem == props.volumeFeePlan.fromDate ? "bg-gray-100" : "";

    function setSelected() {
        if(props.selectedItem == props.volumeFeePlan.fromDate) {
            props.setSelectedItem(null);
        } else {
            props.setSelectedItem(props.volumeFeePlan.fromDate);
        }
    }

    const inactiveColor = "bg-error";
    const activeColor = "bg-confirmation";

    return <Accordion.Item className="hover:bg-gray-100" key={props.volumeFeePlan.fromDate} value={props.volumeFeePlan.fromDate} ref={reportElement}>
        <Accordion.Control onClick={() => setSelected()} className={`relative h-[96px] ${color}`}>
            <div className="inset-4 mb-4 grid text-xs grid-cols-3">
                <BodyItem label="Start Date" value={FormatDate(props.volumeFeePlan.fromDate)}></BodyItem>
                <BodyItem label="End Date" value={FormatDate(props.volumeFeePlan.toDate)}></BodyItem>
                <BodyItemWithDot label="Status" value={props.volumeFeePlan.isActive ? "Active" : "Inactive"} dotColor={props.volumeFeePlan.isActive ? activeColor : inactiveColor}></BodyItemWithDot>
            </div>
        </Accordion.Control>
        <Accordion.Panel className={color}>
            <FeeStepsSection volumeFeePlan={props.volumeFeePlan} currency={props.currency}/>
        </Accordion.Panel>
    </Accordion.Item>
}

interface FeeStepsProps {
    volumeFeePlan: VolumeFeePlan;
	currency: string;
}

function FeeStepsSection({volumeFeePlan, currency}: FeeStepsProps) {
	return (
		<div>
			<div className="flex flex-row gap-1 justify-between">
				<p className="text-sc text-gray-600 uppercase">Wholesale Volume</p>
				<p className="text-sc text-gray-600 uppercase">Wholesale Fee %</p>
			</div>
			<div className="flex flex-col mt-1 border">
				{volumeFeePlan.feeSteps.map((step, i) => {
					const toVolume = (volumeFeePlan.feeSteps[i + 1]?.fromVolume);
					var bgCol = i % 2 === 0 ? 'bg-gray-400' : 'bg-gray-500';
					return (
						<div key={step.fromVolume} className={"grid grid-cols-2 py-2 px-4 " + bgCol}>
							<p className="text-M text-gray-900 font-semibold">{formatStep(step, volumeFeePlan.feeSteps.length, currency, toVolume)}</p>
							<p className="text-M text-gray-900 font-semibold text-right">{(step.wholesaleFee).toFixed(2)}%</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}

function formatStep(step: VolumeFeeStep, stepCount: number, currency: string, toVolume?: number) {
    if(stepCount === 1) {
        return 'Flat rate';
    }
    else if(toVolume !== undefined) {
        return `${step.fromVolume.toLocaleString()} ${currency} - ${toVolume.toLocaleString()} ${currency}`;
    }
    else {
        return `${(step.fromVolume).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ')}+ ${currency}`;
    }
}

function BodyItem(props: { label: string, value: string, styling?: string }) {
	return (
			<div className={"flex flex-col gap-1 " + props.styling ?? ""}>
				<p className="text-sc text-gray-600 uppercase">{props.label}</p>
				<p className="text-M text-gray-900 font-semibold">{props.value}</p>
			</div>
	);
}

function BodyItemWithDot(props: { label: string, value: string, dotColor: string }) {
	return (
        <div className="flex flex-col gap-1 items-baseline">
            <p className="text-sc text-gray-600 uppercase">{props.label}</p>
            <div className={"flex flex-grid items-center"}>
                <div className={""}>
                    <div className={"mr-2 rounded-full h-3 w-3 " + props.dotColor}></div>
                </div>
                <p className="text-M text-gray-900 font-semibold">{props.value}</p>
            </div>
        </div>
	);
}