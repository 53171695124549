import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable, { DataTableSortStatus, TableReportColumn } from "@impulso/common/components/report/DataTable";
import { Loader, LoadingOverlay, ScrollArea } from "@mantine/core";
import { useMemo, useState } from "react";
import { SortDirection } from "src/api/Models";
import {
    useGetArticlePerformanceQuery,
    useGetArticlePerStoreQuery,
    useGetBrandPerformanceQuery,
    useGetCategoryPerformanceQuery,
    useGetSeasonPerformanceQuery,
    useGetStorePerformanceQuery,
    useGetSupplierArticleActivityQuery
} from "src/api/ReportApi";
import { useOrganisationId } from "src/common/security/UseGlobalSecurity";
import { DashFilter } from "src/pages/new/Dashboard";
import {OrganisationId} from "../../../api/models/UserProfile";
import {SerializedError} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import format from "date-fns/format";
import addDays from "date-fns/addDays";

export interface ArticlePerformance {
    category: string;
    sales: number;
}

export interface ArticleDrawerProps {
    startDate: Date;
    endDate: Date;
    sortDirection: SortDirection;
    filter: DashFilter;
    id: string;
}

export function ArticleDrawer(props: ArticleDrawerProps) {
    const organisationId = useOrganisationId();
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
        columnAccessor: "sales",
        direction: props.sortDirection
    });

    const result = getData(props.id, sortStatus.direction, organisationId!, props.filter.toQueryString(), props.startDate, props.endDate);
    return <ArticleDrawerFormat categories={result.dataResult} itemTitle={result.itemTitle} categoryTitle={result.categoryTitle} isLoading={result.isLoading} sortStatus={sortStatus} setSortStatus={setSortStatus} salesError={result.salesError}/>;
}

export interface ArticleDrawerFormatProps {
    categoryTitle: string;
    itemTitle: string;
    categories: {
        category: string,
        sales: number }[],
    isLoading: boolean,
    sortStatus: DataTableSortStatus,
    setSortStatus: (status: DataTableSortStatus) => void,
    salesError: FetchBaseQueryError | SerializedError | undefined
}

export function ArticleDrawerFormat(props: ArticleDrawerFormatProps) {
    const columnData = useMemo(()=> createColumns(props.categoryTitle, props.itemTitle), []);

    if (props.salesError !== undefined) {
        return <div className="w-full h-full flex items-center justify-center flex-col">
            <FontAwesomeIcon className="block w-16 h-16 text-brand" icon={solid("triangle-exclamation")} />
            Failed to load data
        </div>;
    }
    return <div className="h-full relative p-4">
        <ScrollArea className="overflow-auto h-full" offsetScrollbars>
            <DataTable
                records={props.categories}
                columns={columnData}
                sortStatus={props.sortStatus}
                onSortStatusChange={props.setSortStatus}
            />
        </ScrollArea>
        <LoadingOverlay visible={props.isLoading ?? true} loader={
            <div className="flex flex-col content-center items-center">
                <Loader />
            </div>
        } />
    </div>;
}

function createColumns(categoryTitle: string, itemTitle: string) : TableReportColumn<ArticlePerformance>[] {
    return [
        {
            accessor: "category",
            title: categoryTitle,
            sortable: false,
            visibility: "alwaysVisible"
        },
        {
            accessor: "sales",
            title: itemTitle,
            sortable: true,
            visibility: "alwaysVisible"
        },
    ];
}

function getData(id: string, sortDirection: SortDirection, organisationId: OrganisationId, filter: string, startDate: Date, endDate: Date): Test {

    var result: any = null;

    switch(id) {
        case "article": {
            const {data, isLoading, error: salesError} = useGetSupplierArticleActivityQuery(
                {
                    organisationId: organisationId!,
                    sortDirection: sortDirection,
                    filterQuery: filter,
                    limit: -1
                });
            return {categoryTitle:"Article Number/Store", itemTitle:"Last Event",isLoading, salesError: salesError, dataResult:data?.articles.map(c => ({category: c.articleNumber + "/" + c.storeName, sales: c.daysToToday})) ?? [] } as Test;
        }
        case "articlePerformance": {
            const {data, isLoading, error: salesError} = useGetArticlePerformanceQuery(
                {
                    organisationId: organisationId!,
                    startDate: format(startDate, "yyyy-MM-dd"),
                    endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
                    sortDirection: sortDirection,
                    filterQuery: filter,
                    limit: -1
                });
            return {categoryTitle:"Article Number", itemTitle:"Units Sold",isLoading, salesError: salesError, dataResult:data?.articles.map(c => ({category: c.articleNumber, sales: c.quantity})) ?? [] } as Test;
        }
        case "brandPerformance": {
            const {data, isLoading, error: stockError} = useGetBrandPerformanceQuery({
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd"),
                organisationId: organisationId!,
                sortDirection: sortDirection,
                filterQuery: filter,
                limit: -1
            }, {skip: !organisationId});

            return {categoryTitle:"Brand", itemTitle:"Units",isLoading, salesError: stockError, dataResult:data?.brands.map(c => ({category: c.brand, sales: c.quantity})) ?? [] } as Test;
        }
        case "stockPerStore": {
            const {data, isLoading, error: salesError} = useGetArticlePerStoreQuery({
                organisationId: organisationId!,
                sortDirection: sortDirection,
                filterQuery: filter,
                limit: -1
            }, {skip: !organisationId});

            return {categoryTitle:"Article Number/Store", itemTitle:"Units", isLoading, salesError: salesError, dataResult:data?.stores.map(c => ({category: c.articleNumber + "/" + c.storeName, sales: c.quantity})) ?? [] } as Test;
        }
        case "seasonPerformance": {
            const {data, isLoading, error: salesError} = useGetSeasonPerformanceQuery(
                {
                    organisationId: organisationId!,
                    startDate: format(startDate, "yyyy-MM-dd"),
                    endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
                    sortDirection: sortDirection,
                    filterQuery: filter,
                    limit: -1
                });

            return {categoryTitle:"Season", itemTitle:"Quantity", isLoading, salesError: salesError, dataResult:data?.seasons.map(c => ({category: c.seasonTitle, sales: c.quantity})) ?? [] } as Test;
        }
        case "storePerformance": {
            const {data, isLoading, error: salesError} = useGetStorePerformanceQuery({
                organisationId: organisationId!,
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
                sortDirection: sortDirection,
                filterQuery: filter,
                limit: -1
            }, {skip: !organisationId});

            return {categoryTitle:"Store", itemTitle:"Units", isLoading, salesError: salesError, dataResult:data?.stores.map(c => ({category: c.storeName, sales: c.quantity})) ?? [] } as Test;
        }
        case "categoryPerformance": {
            const {data, isLoading, error: salesError} = useGetCategoryPerformanceQuery({
                organisationId: organisationId!,
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
                sortDirection: sortDirection,
                filterQuery: filter,
                limit: -1
            });

            return {categoryTitle:"Category", itemTitle:"Units", isLoading, salesError: salesError, dataResult:data?.categories.map(c => ({category: c.category, sales: c.quantity})) ?? [] } as Test;
        }
    }

    return  result;
}

interface Test{
    categoryTitle: string,
    itemTitle: string,
    isLoading: boolean,
    salesError: FetchBaseQueryError | SerializedError | undefined,
    dataResult: {
        category: string,
        sales: number}[]
}
