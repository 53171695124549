import Search from "@impulso/common/Icons/Search";
import { TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface SimpleSearchProps {
    searchString: string,
    setSearchString: (value: string) => void,
}

export default function SimpleSearch(props: SimpleSearchProps) {
    const searchProps: SearchBarProps = {
        searchString: props.searchString,
        setSearchString: props.setSearchString
    }

    return (<SearchBar {...searchProps} />);
}

type SearchBarProps = {
    searchString: string,
    setSearchString: (value: string) => void,
}

const SearchBar = (props: SearchBarProps) => {
    const {t} = useTranslation();

    return <div className="flex ml-auto v-tablet:ml-0 mt-auto gap-3">
        <TextInput className="min-w-[200px] v-tablet:w-full"
                   placeholder={t("agreements.search") ?? undefined}
                   styles={{
                       label: {fontWeight: "bolder",},
                       input: {borderRadius: 0, '&:hover': {borderColor: '#000'}},
                   }}
                   value={props.searchString}
                   rightSection={<Search/>}
                   onChange={(event) => props.setSearchString(event.currentTarget.value)}
        />
    </div>
}