import ArrowLeft from "@impulso/common/Icons/ArrowLeft";
import { Page } from "@impulso/common/components/Page";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import { IFilter, IFilterItem, generalFilter } from "@impulso/common/filters/generalFilter";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterHeader from "src/common/components/filters/FilterHeader";
import Paths from "src/configuration/Paths";
import { Autocomplete, Loader, MultiSelect } from "@mantine/core";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { TableReport } from "@impulso/common/components/report/TableReport";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import format from "date-fns/format";
import { MissingArticlesListItem, useGetMissingArticlesQuery } from "src/api/StockApi";
import { useGlobalSecurity, useOrganisation } from "src/common/security/UseGlobalSecurity";
import parse from "date-fns/parse";
import Warning from "@impulso/common/Icons/Warning";
import { downloadMissingArticles } from "src/api/DownloadApi";

export type MissingArticleFilter = IFilter<'retailers' | 'ean'>;
const defaultFilterData: Record<'retailers' | 'ean', IFilterItem[]> = {   
    'retailers': [],
    'ean': []
}

export default function MissingArticlesPage() {
    const { accessToken } = useGlobalSecurity()
    const organisation = useOrganisation()!;
    const {data, isFetching, error} = useGetMissingArticlesQuery({organisationId: organisation.id}, {skip: !organisation || !organisation.isSupplier});
    const [filter, setFilter] = useState(generalFilter(defaultFilterData));
    const [exportLoading, setExportLoading] = useState(false);
    const navigate = useNavigate();

    const backButton = () => {
		return <SecondaryButton 
			label="Back" 
			margin="mr-4 mt-1" 
			leftIcon={<ArrowLeft />} 
			onClick={() => navigate(Paths.preferences.index)}
		/>
	}

    const exportButton = async () => {
        try {
            setExportLoading(true);
            const supplierFilter = filter.getItems('retailers', true);
            const eanFilter = filter.getItems('ean', true);
            await downloadMissingArticles(organisation.id, format(new Date(), 'yyyy-MM-dd'), accessToken!, 
                supplierFilter.length === 0 ? undefined : supplierFilter.map(s => s.label).join(','), 
                eanFilter.length === 0 ? undefined : eanFilter.map(s => s.value)[0])
        } catch (e) {
            console.error(e);
        } finally {
            setExportLoading(false);
        }
    }

    if (isFetching) {
        return <Page titleKey="settings.title" hasAccess>
            <div className="w-full, h-full min-h-[404px] flex items-center justify-center">
                <Loader />
            </div>
        </Page>
    }

    if (error) {
        return <Page titleKey="settings.title" hasAccess>
            <div className="w-full, h-full min-h-[404px] flex items-center justify-center ">
                <div className="scale-[4.0] text-brand"><Warning /></div>
            </div>
        </Page>
    }

    const allRows = data?.items ?? [];
    const filteredRows = allRows.filter(t => filter.validate('retailers', t.retailerName) && filter.validate('ean', t.ean))

    return(
        <Page responsive hasAccess titleKey="missingArticles.title" leftAction={backButton()}>
            <div className="pt-4"/>
            <FilterHeader filter={filter} updateFilter={setFilter}>
                <PartnerDropdown filter={filter} setFilter={setFilter} uniquePartners={[...new Set(allRows.map(tr => tr.retailerName))]} />
                <Autocomplete 
                    styles={dropdownStyling}
                    label="EAN"
                    data={[...new Set(allRows.map(tr => tr.ean))]}
                    placeholder="Enter EAN..."
                    limit={5}

                    value={filter.getItems('ean', true).length > 0 ? filter.getItems('ean', true)[0].value : undefined}
                    onChange={value => setFilter(filter.overwrite('ean', [{label: "EAN: " + value, value, active: true}]))}
                />
            </FilterHeader>
            <TableReport 
                columns={getColumns()}
                defaultSort={{accessor: 'lastReceived', direction: 'desc'}}

				pageSize={25}
				rows={filteredRows ?? []}
				idAccessor={row => row.ean}
				isLoading={isFetching}
				loadingText={"Loading missing articles..."}
                exportLink={{isExporting: exportLoading, label: 'Download list (.xlsx)', onExport: exportButton}}
            />
        </Page>
    );
}

function getColumns(): TableReportColumn<MissingArticlesListItem>[] {
	return [
		{
			title: "EAN",
			accessor: "ean",
			sortable: true,
			visibility: "alwaysVisible"
		},
		{
			title: "Last Received",
			accessor: "lastReceived",
			sortable: true,
			visibility: "alwaysVisible",
            render: item => <p>{format(parse(item.lastReceived, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy')}</p>
		},
		{
			title: "Retailer",
			accessor: "retailerName",
			sortable: true,
			visibility: "alwaysVisible"
		},
		{
			title: "Inventories",
			accessor: "inventories",
			sortable: true,
			visibility: "visible",
            render: item => <p className="truncate">{item.inventories.join(', ')}</p>
		}
	]
}

function PartnerDropdown(props: {
    filter: MissingArticleFilter,
    setFilter: (filter: MissingArticleFilter) => void,
    uniquePartners: string[]
}) {
    return (
        <MultiSelect
            styles={dropdownStyling}
            placeholder='Partner...'
            label="Partners"
            data={props.uniquePartners}
            value={props.filter.getItems('retailers', true).map(b => b.value)}
            maxDropdownHeight={250}
            searchable limit={20}
            dropdownComponent="div"
            onChange={(values) => {
                const items = values.map(v => {
                    return {label: v, value: v, active: true}
                })

                props.setFilter(props.filter.overwrite('retailers', items));
            }}
        />
    );
}