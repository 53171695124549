/* eslint-disable @typescript-eslint/no-redeclare */

export type ResultBase<T = {}> = {
    success: boolean;
    errorMessage: string;
    result: T;
}
export module ResultBase {
    export function unwrap<T>(result: ResultBase<T>): T {
        if (!result.success) {
            throw new Error(result.errorMessage);
        }

        return result.result;
    }
}
