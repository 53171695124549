import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {GlobalAppsettings} from "../GlobalSettings";
import "./init";

export function setupIntercomConfig(): ThunkAction<void, RootState, unknown, AnyAction> {
    return async () => {
        if (!window.Intercom)
        {
            console.error("Failed to initiate Intercom, global function isn't defined.");
            return;
        }

        console.log("Boot intercom");

        window.Intercom('boot', {
            app_id: GlobalAppsettings.InterCom,
            alignment: 'right',
            horizontal_padding: 8,
            vertical_padding: 8,
            hide_default_launcher: false
        });
    }
}

export interface IntercomUser {
    name: string,
    userId: string,
    userHash: string,
    email: string
}

export function setupUser(user: IntercomUser): ThunkAction<void, RootState, unknown, AnyAction> {
    return async () => {
        if (!window.Intercom)
        {
            console.error("Failed to initiate Intercom user, global function isn't defined.");
            return;
        }

        console.log("Setup intercom user");

        window.Intercom('update', {
            name: user.name,
            user_id: user.userId,
            email: user.email,
            user_hash: user.userHash
        });
    }
}
