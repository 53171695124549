import React, {useCallback, useRef, useState} from "react";
import {Organisation} from "../../../api/models/UserProfile";
import {PrimaryButton} from "@impulso/common/components/buttons/PrimaryButton";
import ArrowUp from "@impulso/common/Icons/ArrowUp";
import ArrowDown from "@impulso/common/Icons/ArrowDown";
import CheckboxIcon from "@impulso/common/Icons/CheckboxIcon";
import {CheckMarkIcon} from "@impulso/common/Icons/CheckMarkIcon";
import {OnClickOutside} from "@impulso/common/common/utilities/OnClickoutside";
import { DashFilter, DashFilterKey } from "src/pages/new/Dashboard";

interface MultiSelectItem {
    value: string, 
    label: string, 
    shortLabel: string
}

export type MultiSelectProps = {
    title: string,
    filterKey: DashFilterKey,
    organisation: Organisation,
    filter: DashFilter,
    setFilter: (filter: DashFilter) => void,
    items: MultiSelectItem[],
    isDisabled: boolean
}

export function MultiSelectElement(props: MultiSelectProps) {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    OnClickOutside(wrapperRef, useCallback(() => {
        setOpen(false);
    }, []));
    
    const activeItems = props.filter.getItems(props.filterKey, true).map(i => {
        var item = props.items.find(p => p.value === i.value);
        return item?.shortLabel ?? item?.label ?? "";
    });
    
    function getLabel(item: MultiSelectItem)
    {
        if (item.shortLabel === undefined || item.shortLabel === item.label) {
            return item.label;
        } 
        return item.shortLabel + " - " + item.label;
    }

    return <div className={`relative`} ref={wrapperRef}>
        <PrimaryButton disabled={props.items.length === 0 || props.isDisabled} rightIcon={open ? <ArrowUp height={"16"} width={"16"}/> : <ArrowDown height={"16"} width={"16"}/>} textSize={"text-l"} padding="p-[14px]"
                       label={activeItems.length === 1 ? activeItems[0] : activeItems.length > 1 ? activeItems.length + " " + props.title : "All " + props.title} 
                       onClick={() => {
            if (props.isDisabled) {
                return;
            }
            setOpen(!open);
        }}/>
        <div
            className={`absolute ${open ? '' : 'scale-y-0 opacity-0'} origin-top right-0 bg-white drop-shadow-md w-[296px] z-20 transition-all`}>
            <div className="w-full p-2">
                <div className="p-2 text-gray-600 font-[400] text-s(c) uppercase">{props.title}</div>
                {props.items?.map(i =>
                    <ItemElement filerKey={props.filterKey} filter={props.filter} setFilter={props.setFilter} key={i.label} label={getLabel(i)} value={i.value} defaultSelect={activeItems.includes(i.shortLabel ?? i.label)}/>
                )}
            </div>
        </div>
    </div>
}

interface ItemElementProps {
    label: string,
    value: string,
    filter: DashFilter,
    setFilter: (filter: DashFilter) => void,
    filerKey: DashFilterKey,
    defaultSelect?: boolean
}

function ItemElement(props: ItemElementProps) {
    const hoverStyle = 'hover:bg-brand-100 hover:text-brand-600 hover:cursor-pointer';
    const isSelected = props.defaultSelect;

    return <div key={props.label}
                className={`flex gap-4 p-2 text-xs ${hoverStyle}`}
                onClick={() => {
                    if (!isSelected) {
                        props.setFilter(props.filter.addItem(props.filerKey, {
                            value: props.value,
                            label: props.label,
                            active: true
                        }));
                    } else {
                        props.setFilter(props.filter.removeItem(props.filerKey, {
                            value: props.value,
                            label: props.label,
                            active: true
                        }));
                    }
                }}>
        {isSelected ? <CheckMarkIcon/> : <CheckboxIcon/>}
        <p>{props.label}</p>
    </div>
}