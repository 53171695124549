import Plus from "@impulso/common/Icons/Plus";
import { Menu } from "@mantine/core";
import { IFilter } from "@impulso/common/filters/generalFilter";

type FilterHeaderProps = {
    filter: IFilter<any>;
    updateFilter: (filter: IFilter<any>) => void;
    children?: JSX.Element | JSX.Element[] | never[];
}

export default function FilterHeader(props: FilterHeaderProps) {

    const activeFilters = props.filter.getAllActiveItems()
        .map(a => <ActiveFilterBox key={a.key + a.item.value} title={a.item.label} onClick={() => {
            props.updateFilter(props.filter.setActive(a.key, a.item.value, false));
    }} />)

    return (
    <div className="flex flex-wrap gap-2 my-4 text-sm">
        <Menu position="bottom-start">
        <Menu.Target>
            <div className="bg-gray-200 hover:bg-gray-300 hover:cursor-pointer w-fit flex items-center gap-1 p-1 px-2 select-none"><p>Add filter</p><div className="scale-75"><Plus/></div></div>
        </Menu.Target>

        <Menu.Dropdown className="p-4 text-sm min-w-[300px] max-w-[450px]">
            <div className="flex flex-col gap-4">
                {props.children}
            </div>
        </Menu.Dropdown>
        </Menu>
        
        {activeFilters}
    </div>
    );

}



function ActiveFilterBox(props: {title: string, onClick: () => void})
{
    return (
        <div className="text-white bg-black hover:bg-gray-900 hover:cursor-pointer w-fit flex items-center gap-1 p-1 px-2 select-none"
        onClick={props.onClick}>
            <p>{props.title}</p>
            <div className="scale-75 rotate-45"><Plus/></div>
        </div>
    );
}
