export default function CheckboxIcon() {
    return <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_237_8640)">
            <rect x="1" y="1" width="22" height="22" stroke="currentcolor" strokeWidth="2"/>
        </g>
        <defs>
            <clipPath id="clip0_237_8640">
                <rect width="24" height="24" fill="currentcolor"/>
            </clipPath>
        </defs>
    </svg>
}


