import { useState } from "react";
import { colors } from "@impulso/common/Theme";
import MenuIcon from "@impulso/common/Icons/MenuIcon";
import Notification from "@impulso/common/Icons/Notification";
import { MainLogo } from "@impulso/common/logos/MainLogo";
import ArrowLeft from "@impulso/common/Icons/ArrowLeft";
import XMark from "@impulso/common/Icons/XMark";
import { hasModule, useOrganisation } from "../../security/UseGlobalSecurity";
import { OrganisationId } from "../../../api/models/UserProfile";
import { Avatar, Divider, Loader, UnstyledButton } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowRight from "@impulso/common/Icons/ArrowRight";
import { Link, useMatch, useNavigate } from "react-router-dom";
import {PrimaryLink, useNavigationConfig} from "../../../configuration/Navigation";
import { useTranslation } from "react-i18next";
import { ModuleId } from "../../../api/models/Module";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NavLinkIcon, PrimaryNavLinkProps, SectionProps } from "@impulso/common/components/NavigationSidebarView";
import React from "react";

interface MobileNavHeaderProps {
    organisations: {id: OrganisationId, name: string, defaultCurrency: string}[],
    organisationLoading: boolean,
    userName: string,
    currentOrganisationId: OrganisationId,
    setCurrentOrganisationId: (id: OrganisationId) => void,
    setNotificationOpen: () => void;
};

export default function MobileNavigationHeader(props: MobileNavHeaderProps) {
    //If undefined, use the entire section object and show the org dropdown. Otherwise just display the sublinks of the section and a back button.
    //!Should only be set to an object if it has sublinks!
    const [currentSection, setCurrentSection] = useState<PrimaryLink | undefined>(undefined);
    const [open, setMenuOpen] = useState(false);
    const [changeOrg, setChangeOrg] = useState(false);
    const navigate = useNavigate();
    const organisation = useOrganisation();
    const {t} = useTranslation();
    const {pathname: currentPath} = useMatch("/*") ?? {pathname: null};

    const modules = organisation?.modules.map(m => m.identifier);
    const role = organisation?.role;
    const navigationConfig = useNavigationConfig(modules ?? [], role ?? "Member");

    const sections: Omit<SectionProps, "first">[] = navigationConfig.map(section => ({
        key: section.id,
        links: section.links.map(link => ({
            onClick: () => {
                if (link.external) {
                    window.open(link.path, '_blank')
                }
            },
            onClickAction: (value: boolean) => {
                setMenuOpen(value); 
                if (value) {
                    setCurrentSection(link as PrimaryLink);
                } else {
                    navigate(link.path);
                }
            },
            key: link.text,
            text: t(link.text),
            icon: link.icon,
            notice: undefined,
            active: currentPath === link.path,
            open: currentPath?.startsWith(link.path),
            links: link.links?.map(subLink => ({
                onClick: () => {setMenuOpen(false); navigate(subLink.path);},
                key: subLink.text,
                text: t(subLink.text),
                active: currentPath === subLink.path
            }))
        })),
    }));

    return (
        <>
        { (currentSection === undefined && !changeOrg)  || !open ?
            <MainMobileNavHeader isOpen={open} setOpen={(value: boolean) => {setMenuOpen(value); setCurrentSection(undefined)}} openNotif={props.setNotificationOpen} setChangeOrg={() => setChangeOrg(false)} changeOrg={changeOrg} /> :
            <SubMobileNavHeader isOpen={open} setOpen={(value: boolean) => {setMenuOpen(value); setCurrentSection(undefined)}} openNotif={props.setNotificationOpen} setSection={() => setCurrentSection(undefined)} setChangeOrg={() => setChangeOrg(false)} changeOrg={changeOrg}/>
        }
        <MobileNavContent 
            {...props} 
            isOpen={open} 
            setOpen={() => setMenuOpen(false)} 
            changeOrg={changeOrg} 
            sections={sections} 
            setChangeUser={(value: boolean) => setChangeOrg(value)} 
            currentSection={currentSection} 
            currentPath={currentPath}
            navigate={(path: string) => {navigate(path); setMenuOpen(false)}} />
        </>
    );
}

interface HeaderProps {
    isOpen: boolean,
    changeOrg: boolean,
    setOpen: (value: boolean) => void,
    openNotif: () => void, 
    setChangeOrg: () => void
    setSection?: () => void,
}

function MainMobileNavHeader(props: HeaderProps) {
    return ( <div className="sticky flex justify-between items-center top-0 min-h-[64px] pl-6 pr-4 bg-gray-100 z-50 w-full">
        <Link to="/">
            <MainLogo width="119.56" height="32" color={colors.brand.DEFAULT}/>
        </Link>
        <div className="flex items-center">
            {!props.isOpen && <div className="hover:cursor-pointer p-2" onClick={props.openNotif}><Notification /></div>}
            <div className="hover:cursor-pointer p-2" onClick={() => props.setOpen(!props.isOpen)}>{props.isOpen ? <XMark/> : <MenuIcon />}</div>
        </div>
    </div> )
}

function SubMobileNavHeader(props: HeaderProps) {
    return ( <div className="sticky flex justify-between items-center top-0 min-h-[64px] pr-4 bg-gray-100 z-50 w-full">
        <div onClick={() => { if (props.setSection) props.setSection(); if (props.changeOrg) props.setChangeOrg()}} className="flex items-center gap-4 px-6 h-full"><ArrowLeft/>Menu</div>
        <div className="flex items-center">
            {!props.isOpen && <div className="hover:cursor-pointer p-2" onClick={props.openNotif}><Notification /></div>}
            <div className="hover:cursor-pointer p-2" onClick={() => {props.setOpen(!props.isOpen); props.setChangeOrg();}}>{props.isOpen ? <XMark/> : <MenuIcon />}</div>
        </div>
    </div> )
}

interface MobileNavContentProps {
    isOpen: boolean,
    setOpen: () => void;
    organisationLoading: boolean,
    setChangeUser: (value: boolean) => void,
    organisations: {id: OrganisationId, name: string, defaultCurrency: string}[],
    currentOrganisationId: OrganisationId,
    userName: string,
    sections: SectionProps[],
    currentSection: PrimaryLink | undefined,
    currentPath: string | null,
    changeOrg: boolean,
    navigate: (path: string) => void,
    setCurrentOrganisationId: (id: OrganisationId) => void,
}

function MobileNavContent(props: MobileNavContentProps) {
    const {t} = useTranslation();
    
    return <div className={"absolute top-16 bottom-0 left-0 right-0 bg-gray-100 z-50 origin-top overflow-y-scroll duration-200 transition-[height] " + (props.isOpen ? "h-full" : "h-0 pointer-events-none")}>
        {props.changeOrg 
        ? <div>
            {
                props.organisations.map((org, i) => {
                    return <div 
                            className={"text-2xl pl-14 pr-6 py-4 flex relative items-center hover:bg-gray-200 active:bg-gray-300 " + (props.currentOrganisationId === org.id ? "text-brand" : "")} 
                            onClick={() => {props.setChangeUser(false); props.setCurrentOrganisationId(org.id)}}>
                        {org.name}
                    </div>
                })
            }
        </div>
        : <div>
            <div onClick={() => props.setChangeUser(true)}><UserNavItem {...props} /></div>
            { props.currentSection === undefined ? props.sections.map((section, i) => {
                if (i === 0) return <Section key={section.key} links={section.links} first />

                return <>
                    <Divider my="sm" w={'100%'}/>
                    <Section key={section.key} links={section.links} />
                </>
            }) : <div className="flex flex-col mt-6">{props.currentSection.links?.map((link) => {
                return <NavItem {...link} active={props.currentPath === link.path} text={t(link.text)} icon={undefined} key={link.path} onClick={() => props.navigate(link.path)} onClickAction={() => {}} />
            })} </div>}
        </div>}
    </div>
}

function UserNavItem(props: MobileNavContentProps) {
    const Picture = () => {
        const initials = toInitials(props.userName);
        if (initials) {
            return <Avatar alt={props.userName} className="inline-block align-middle"
                           styles={{placeholder: {color: "white", backgroundColor: colors.brand[400], fontWeight: 400}}}
                           size={32} radius="xl">{initials}</Avatar>
        }

        return <FontAwesomeIcon icon={regular("circle-user")} className="h-8 inline align-middle text-brand-400"/>
    }

    const currentOrg = props.organisations.find(o => o.id === props.currentOrganisationId);

    return props.organisationLoading ? <div className="px-6 py-4"><Loader mx={'auto'}/></div> : 
    <div className="flex items-center text-l gap-4 hover:bg-gray-200 active:bg-gray-300 px-6 py-4">
        <Picture />
        <div className="border-l border-gray-500 h-[32px] w-[1px]" />
        <p className="w-full">{currentOrg?.name}</p>
        <div className="w-[24px] h-[24px] flex items-center mb-[2px]"><ArrowRight /></div>
    </div>;
}

function Section(props: SectionProps) {
    return <div className={"flex flex-col " + (props.first ? "mt-6" : "")}>
        {props.links.map((({key, ...link}) => {
            return <NavItem key={key} {...link} />
        }))}
    </div>
}

function NavItem(props: PrimaryNavLinkProps) {
    const hasChildren = (props.links ?? []).length > 0;
    const open = props.open && hasChildren;
    const active = props.active || open;

    return (<UnstyledButton className={"px-6 py-4 flex relative items-center hover:bg-gray-200 active:bg-gray-300 " + (active ? "text-brand" : "text-black")} onClick={() => {props.onClick(); if (props.onClickAction) props.onClickAction(hasChildren)}}>
        <div className="mr-4 w-[24px] h-[24px]"><NavLinkIcon icon={props.icon} notice={props.notice} foldout={props.foldout} size="first:scale-[1.5] first:ml-1"/></div>
        <p className="text-2xl">{props.text}</p>
        {hasChildren && <div className="absolute right-[24px]"><ArrowRight/></div>}
        </UnstyledButton>);
}

function toInitials(name?: string): string | undefined {

    if (name && name.match(/.+@.+/)) {
        return emailToInitials(name);
    }

    const initials = name?.trim().split(" ").map(a => a[0]?.toUpperCase()) ?? [];

    if (initials.length === 0) {
        return undefined
    } else if (initials.length === 1) {
        return initials[0];
    } else {
        return initials[0] + initials[initials.length - 1];
    }
}

function emailToInitials(email?: string) {
    const name = (email?.split("@") ?? [])[0];
    const initials = name?.split(".").map(a => a[0].toUpperCase());

    if (initials.length === 0) {
        return undefined
    } else if (initials.length === 1) {
        return initials[0];
    } else {
        return initials[0] + initials[initials.length - 1];
    }
}