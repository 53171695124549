import { MantineSize, NumberInput, NumberInputProps, Textarea, TextareaProps, TextInput, TextInputProps} from "@mantine/core";
import {colors} from "@impulso/common/Theme";
import React from "react";

const selectStyle = {
    input:{
        '&:hover': {borderColor: colors.gray[900]},
        '&:focus': {borderColor: colors.brand[600]},
        '&:focus-within':{borderColor: colors.brand[600]},
        borderRadius: 0
    },
    dropdown: {borderRadius: 0},
    item: {borderRadius: 0},
    value: {borderRadius: 0}
    
}

type TextInputFieldProps = {
    label: string;
    size?: MantineSize;
    disabled?: boolean;
    required?: boolean;
    placeholder?: string;
    type?: React.HTMLInputTypeAttribute;
} & Pick<TextInputProps, | "error"| "onBlur" | "onChange" | "onFocus" | "value" >;
export function TextInputField({label, ...props}:TextInputFieldProps){
    return(<TextInput required={props.required ?? true} disabled={props.disabled} label={label} size={props.size ?? 'xs'} placeholder={props.placeholder ?? label ?? undefined} className="py-2" styles={selectStyle} {...props}  />);
}

type TextAreaSelectProps = {
    label: string
    autosize?: boolean
    minRows?: number
    maxRows?: number
    size?: MantineSize
} & Pick<TextareaProps, | "error"| "onBlur" | "onChange" | "onFocus" | "value" | "disabled" >;
export function TextAreaInputField({label, ...props}:TextAreaSelectProps){
    return(<Textarea label={label} size={props.size ?? 'xs'} minRows={props.minRows ?? 2} maxRows={props.maxRows ?? 2} autosize={props.autosize} placeholder={label ?? undefined} className="py-2" styles={selectStyle} {...props} />);
}

type NumberInputFieldProps = {
    label: string
    needsPrecision: boolean
    size?: MantineSize
    disabled?: boolean
    required?: boolean
} & Pick<NumberInputProps, | "error"| "onBlur" | "onChange" | "onFocus" | "value" >;
export function NumberInputField({label, needsPrecision, ...props}: NumberInputFieldProps){
    return(<NumberInput hideControls decimalSeparator={"."} precision={needsPrecision ? 2: 0} required={props.required ?? true} disabled={props.disabled} label={label} size={props.size ?? 'xs'} placeholder={label ?? undefined} className="py-2" styles={selectStyle} {...props} />);

}

            