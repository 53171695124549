import format from "date-fns/format";
import { NotificationStatus, useReadNotificationMutation } from "../../api/NotificationApi";
import CheckMark from "@impulso/common/Icons/CheckMark";
import UnCheckMark from "@impulso/common/Icons/UnCheckMark";
import { useNavigate } from "react-router-dom";
import { NotificationSpec, getNotificationSpec } from "./NotificationConfig";
import { useOrganisationId } from "../../common/security/UseGlobalSecurity";
import {OrganisationNotification} from "./Notification";

interface IconProps{
  status: NotificationStatus;
}

function Icon({status}: IconProps) {
  if (status === "Unread")
  {
    return <div className="rounded-full bg-brand-500 w-3 h-3 self-center"></div>
  }
  switch(status)
  {
    case "Pending":
      return <div className="w-4"><CheckMark/></div>
    case "CompletedByUser":
      return <div className="w-4"><UnCheckMark/></div>
    case "CompletedBySystem":
      return <></>
  }
}

interface NotificationMessageProps {
  notification: OrganisationNotification;
  onClose: ()=>void;
}

export function NotificationRow({notification, onClose}: NotificationMessageProps) {
  const navigate = useNavigate();
  const [read] = useReadNotificationMutation();
  const orgId = useOrganisationId();

  async function onMessageClicked(info: NotificationSpec)
  {

    if (notification.status === "Unread") {
      read({ organisationId: orgId!, notificationId: notification.id });

    }

    if (info.path === undefined)
    {
      return;
    }

    navigate(info.path, {state: info.state});
    onClose();
  }

  const notificationSpec = getNotificationSpec(notification.type, notification.meta);

  return <div className="py-4 border-b">
    <div className="flex justify-between">
      <div className="flex flex-row basis-3/4 gap-2 truncate flex-start">

        <Icon status={notification.status}/>
        <button className={"text-S text-gray-900 bg-white truncate " + (!notificationSpec.noLink && " underline ")} 
            onClick={()=>onMessageClicked(notificationSpec)} >
            {notificationSpec.message}
        </button>
      </div>
      <span className="text-S text-gray-900                                                          ">
        {format(notification.created, "dd MMM ‘yy")}
      </span>
    </div>
  </div>
  ;
}