
export default function TrashCan() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_175_6966)">
                <path d="M0.666656 4H15.3333" stroke="currentcolor" stroke-width="1.5"/>
                <path d="M2.66666 4H13.3333V14.6667H2.66666V4Z" stroke="currentcolor" stroke-width="1.5"/>
                <path d="M6 6.66663V12" stroke="currentcolor" stroke-width="1.5"/>
                <path d="M10 6.66663V12" stroke="currentcolor" stroke-width="1.5"/>
                <path d="M5.33334 4.00004C5.33334 2.52728 6.52725 1.33337 8.00001 1.33337C9.47274 1.33337 10.6667 2.52728 10.6667 4.00004" stroke="currentcolor" stroke-width="1.5"/>
            </g>
            <defs>
                <clipPath id="clip0_175_6966">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

